<template>
  <b-modal
    id="modal-alert-text"
    body-class="p-0"
    hide-header
    hide-footer
    centered
    :size="size"
  >
    <div class="modal-alert-text">
      <div class="body">
        <p v-html="textModal"></p>
        <div v-for="(item, index) in arrModal" :key="index">
          <b-row class="mb-3">
            <b-col cols="5">
              <h5 class="text-right">{{ item.tab }} :</h5>
            </b-col>
            <b-col cols="7">
              <p
                class="text-left"
                v-for="(detail, index) in item.details"
                :key="index"
              >
                {{ detail.message }}
              </p>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="footer-modal">
        <b-button class="btn-filter" @click="closeModal"> ตกลง </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    textModal: {
      type: String,
      default: "",
    },
    arrModal: {
      type: Array,
      default: [],
      required: false,
    },
    size: {
      type: String,
      required: false,
      default: "md",
    },
  },
  methods: {
    closeModal() {
      this.$emit("clearArrModal");
      this.$bvModal.hide("modal-alert-text");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-alert-text {
  padding: 20px;

  .body {
    p {
      margin: 0;
      text-align: center;
    }

    .error-detail {
      display: flex;
      justify-content: center;
    }
  }

  .footer-modal {
    text-align: center;
    margin-top: 10px;

    .btn-filter {
      width: 100%;
      max-width: 100px;
    }
  }
}
</style>
